.company {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    color: #565656;
    text-align: start;
}

.company__title {
    margin: 0;
    font-size: 50px;
    font-weight: 500;
}

.company__text {
    margin: 0;
    padding-left: 18px;
    font-size: 30px;
    max-width: 930px;
    border-left: 1px solid #000;
}

@media screen and (max-width: 1800px) {
    .company__title {
        font-size: 30px;
    }

    .company__text {
        font-size: 20px;
        max-width: 800px;
    }
}

@media screen and (max-width: 1500px) {
    .company__text {
        max-width: 600px;
    }
}

@media screen and (max-width: 1200px) {
    .company__title {
        font-size: 24px;
    }

    .company__text {
        font-size: 18px;
        max-width: 525px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 992px) {
    .company__title {
        font-size: 20px;
    }

    .company__text {
        font-size: 16px;
        max-width: 400px;
    }
}

@media screen and (max-width: 768px) {
    .company {
        flex-direction: column;
    }

    .company__text {
        margin-top: 10px;
        max-width: 100%;
    }
}