.delivery {
    width: 100%;
    margin: auto;
    max-width: 1920px;
    color: #565656;
}

.delivery__title {
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    text-align: start;
    margin: 0;
}

.delivery__text {
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    text-align: start;
    margin-top: 30px;
}

.delivery__image {
    width: 84%;
    object-fit: contain;
}

@media screen and (max-width: 1800px) {
    .delivery__title {
        font-size: 40px;
        line-height: 40px;
    }

    .delivery__text {
        font-size: 28px;
        margin-top: 15px;
    }
}

@media screen and (max-width: 1200px) {
    .delivery__title {
        font-size: 24px;
        line-height: 26px;
    }

    .delivery__text {
        font-size: 18px;
        line-height: 20px;
    }
}

@media screen and (max-width: 768px) {
    .delivery__title {
        font-size: 20px;
        line-height: 20px;
    }

    .delivery__text {
        font-size: 16px;
        line-height: 16px;
    }
}