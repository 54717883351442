.payment {
    max-width: 1920px;
    margin: auto;
    display: grid;
    gap: 40px;
}

.payment__block {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.payment__title {
    font-size: 50px;
    margin: 0;
    font-weight: 500;
    text-align: start;
    color: #565656;
    width: 50%;
}

.payment__textBlock {
    width: 50%;
    padding-left: 18px;
    border-left: #565656 1px solid;
}

.payment__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.payment__text {
    font-size: 40px;
    font-weight: 400;
    margin: 0;
    text-align: start;
    color: #565656;
}

@media screen and (max-width: 1800px) {
    .payment__title {
        font-size: 30px;
    }

    .payment__text {
        font-size: 24px;
    }
}

@media screen and (max-width: 1500px) {
    .payment__title {
        width: 40%;
    }

    .payment__textBlock {
        width: 60%;
    }
}

@media screen and (max-width: 1200px) {
    .payment__title {
        font-size: 24px;
    }

    .payment__text {
        font-size: 18px;
    }
}

@media screen and (max-width: 992px) {
    .payment__title {
        font-size: 20px;
    }

    .payment__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .payment {
        gap: 20px;
    }
    
    .payment__block {
        display: grid;
        gap: 10px;
    }

    .payment__title {
        width: 100%;
    }

    .payment__textBlock {
        width: 100%;
        padding: 0;
        border: none;
    }
}