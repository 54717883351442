.loading_view_none {
    display: none;
}

.product {
    max-width: 1920px;
    margin: auto;
    display: grid;
    gap: 40px;
}

.product__navigation {
    position: fixed;
    right: 60px;
    display: grid;
    text-align: center;
    font-size: 20px;
    line-height: 70px;
    border: 1px solid #565656;
    width: 300px;
}

.product__link {
    color: black;
    text-decoration: none;
    border-bottom: 2px solid;
}

.product__link:last-of-type {
    border: none;
}

.product__title {
    font-weight: 500;
    font-size: 64px;
    line-height: 42px;
    text-align: start;
    color: #565656;
    margin: 40px 0 40px;
}

.option__list {
    padding: 0;
}

.description {
    width: 100%;
    display: grid;
    grid-template-columns: 500px calc(100% - 500px - 20px);
    column-gap: 20px;
}

.description__info {
    max-width: 100%;
    height: 300px;
    display: grid;
    align-content: space-between;
}

.description__imagesBlock {
    max-width: 500px;
    display: grid;
    gap: 16px;
    position: relative;
}

.description__imageWrap {
    position: relative;
}

.description__image {
    width: 100%;
    height: 300px;
    object-fit: contain;
}

.description__imagesList {
    width: 100%;
    padding: 0;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    list-style-type: none;
}

.description__imagesList::-webkit-scrollbar {
    background-color: rgba(235, 186, 142, 0.2);
    width: 10px;
}

.description__imagesList::-webkit-scrollbar-thumb {
    background-color: rgba(235, 186, 142, 0.6);
    width: 10px;
}

.description__imageItem {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.description__imageItem_checked {
    border: 3px rgba(245, 217, 191, 1) solid;
}

.description__switchImages {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 125px;
    right: 0;
}

.description__switchButton {
    margin: 0;
    border: none;
    background-color: transparent;
    width: 48px;
    height: 48px;
    position: absolute;
    top: 45%;
    right: 0;
}

.description__switchButton_route_right {
    transform: rotate(180deg);
    left: 0;
    padding-bottom: 50px;
}

.description__text {
    font-size: 24px;
    text-align: start;
    white-space: pre-line;
    color: #565656;
}

.description__order {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.description__price {
    font-size: 36px;
}

.description__button {
    width: 180px;
    height: 60px;
    border-radius: 5px;
    font-size: 28px;
    background-color: rgba(235, 186, 142, 0.6);
    border: none;
}

.option {
    width: 80%;
}

.option__title {
    font-size: 24px;
    text-align: start;
}

.option__label {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
}

.option__item {
    display: flex;
    justify-content: space-evenly;
    text-align: start;
}

.option__image {
    width: 144px;
}

.option__text {
    width: 40%;
}

.option__shop {
    width: 20%;
}

.popupWrap {
    width: 65vw;
    height: 80vh;
    position: relative;
}

.popupImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.description__switchButton_place_popup {
    width: 66px;
    height: 64px;
    top: 50%;
}

.switchImg {
    width: 64px;
    height: 64px;
}

@media screen and (max-width: 1800px) {
    .product__title {
        font-size: 50px;
        margin: 30px 0 30px;
    }
}

@media screen and (max-width: 1500px) {
    .description__text {
        font-size: 20px;
    }

    .description__price {
        font-size: 28px;
    }

    .product__title {
        font-size: 32px;
        line-height: 32px;
    }

    .product__navigation {
        font-size: 16px;
        line-height: 60px;
        border: 1px solid #565656;
        width: 230px;
    }

    .product__link {
        border-bottom: 1px solid #565656;
    }
}

@media screen and (max-width: 1200px) {
    .product {
        gap: 20px;
    }

    .product__title {
        font-size: 24px;
        line-height: 24px;
        margin: 20px 0 30px;
    }

    .description {
        grid-template-columns: 400px calc(100% - 400px - 20px);
    }

    .description__text {
        font-size: 16px;
    }

    .description__imagesBlock {
        gap: 6px;
        width: 400px;
    }

    .description__image {
        height: 250px;
    }

    .description__imagesList {
        margin: 0;
    }

    .description__imageItem {
        width: 60px;
        height: 60px;
    }

    .description__imagesList::-webkit-scrollbar {
        width: 6px;
    }

    .description__imagesList::-webkit-scrollbar-thumb {
        width: 6px;
    }

    .description__switchButton {
        top: 100px;
    }

    .description__switchButton_route_right {
        top: 103px;
    }

    .description__price {
        font-size: 24px;
        margin: 4px;
    }

    .description__button {
        width: 100px;
        height: 40px;
        font-size: 14px;
    }

    .description__order {
        flex-direction: column;
        align-items: flex-start;
    }

    .description__switchButton_place_popup {
        top: 50%;
    }

    .product__navigation {
        font-size: 14px;
        line-height: 50px;
        width: 180px;
    
    }
}

@media screen and (max-width: 992px) {
    .description {
        gap: 10px;
        grid-template-columns: 250px calc(100% - 250px - 10px);
    }

    .description__info {
        height: 270px;
    }

    .description__image {
        width: 250px;
        height: 260px;
    }

    .description__imageItem {
        width: 50px;
        height: 50px;
        border-radius: 10px;
    }

    .description__imagesBlock {
        gap: 6px;
        max-width: 250px;
    }

    .description__price {
        font-size: 20px;
        margin: 4px;
    }

    .description__button {
        width: 80px;
        height: 30px;
        font-size: 14px;
    }

    .description__order {
        flex-direction: column;
        align-items: flex-start;
    }

    .description__switchButton_place_popup {
        top: 50%;
    }

    .product__navigation {
        font-size: 14px;
        line-height: 40px;
        width: 144px;
        right: 20px;
    }
}

@media screen and (max-width: 768px) {
    .product__title {
        font-size: 20px;
        line-height: 20px;
    }

    .description {
        width: 99%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .description__imagesBlock {
        max-width: 90%;
    }

    .description__image {
        width: 90%;
    }

    .product__navigation {
        display: none;
    }

    .description__info {
        max-width: 99%;
        height: 225px;

    }
}

@media screen and (max-width: 578px) {
    .option {
        width: 94%;
    }

    .description__switchButton {
        top: 75px;
    }

    .description__switchButton_route_right {
        top: 77px;
    }

    .description__switchButton_place_popup {
        top: 50%;
    }

    .description__info {
        height: 260px;

    }
}

@media screen and (max-width: 410px) {
    .product {
        gap: 10px;
    }

    .description {
        max-width: 296px;
    }

    .description__info {
        height: 325px;
    }
}