.catalog {
    max-width: 1920px;
    margin: auto;
}

.catalog__title {
    font-weight: 500;
    font-size: 64px;
    line-height: 42px;
    text-align: start;
    color: #565656;
    margin: 40px 0 40px;
}

.catalog__items {
    display: flex;
    gap: 100px;
    justify-content: center;
    flex-wrap: wrap;
}

.itemPr {
    border: 1px solid #707070;
    text-decoration: none;
    height: 730px;
    width: 480px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.itemPr__name {
    font-size: 30px;
    line-height: 22px;
    color: #565656;
    text-decoration: none;
}

.itemPr__image {
    height: 540px;
    width: 390px;
    object-fit: contain;
}

@media screen and (max-width: 1800px) {
    .catalog__title {
        font-size: 50px;
        margin: 30px 0 30px;
    }

    .itemPr {
        height: 520px;
        width: 380px;
    }

    .itemPr__image {
        height: 420px;
        width: 378px;
    }
}

@media screen and (max-width: 1500px) {
    .catalog__title {
        font-size: 32px;
        line-height: 32px;
    }
    
    .catalog__items {
        gap: 80px;
    }

    .itemPr {
        height: 480px;
        width: 324px;
    }

    .itemPr__image {
        height: 360px;
        width: 300px;
    }
}

@media screen and (max-width: 1200px) {
    .catalog__title {
        font-size: 24px;
        line-height: 26px;
        margin: 20px 0 30px;
    }

    .itemPr {
        width: 220px;
        height: 350px;
    }

    .itemPr__name {
        font-size: 18px;
        line-height: 20px;
    }

    .itemPr__image {
        height: 230px;
        width: 218px;
    }
}

@media screen and (max-width: 992px) {

    .catalog__items {
        gap: 16px;
    }

    .itemPr {
        width: 200px;
        height: 300px;
    }

    .itemPr__name {
        font-size: 16px;
        line-height: 18px;
    }

    .itemPr__image {
        height: 200px;
        width: 198px;
    }
}

@media screen and (max-width: 768px) {
    .catalog__title {
        font-size: 20px;
        line-height: 20px;
    }

    .itemPr {
        width: 160px;
        height: 200px;
    }

    .itemPr__name {
        font-size: 14px;
        line-height: 16px;
    }
    .itemPr__image {
        height: 150px;
        width: 158px;
    }
}

@media screen and (max-width: 576px) {
    .catalog__items {
        gap: 20px;
    }
}