.address {
    max-width: 1920px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.address__title {
    font-size: 50px;
    margin: 0;
    font-weight: 500;
    text-align: start;
    color: #565656;
    width: 50%;
}

.contacts {
    display: grid;
    gap: 30px;
    padding-left: 18px;
    border-left: #565656 1px solid;
    width: 50%;
}

.contacts__text {
    font-size: 40px;
    font-weight: 400;
    margin: 0;
    text-align: start;
    color: #565656;
}

.contacts__map {
    width: 98%;
}

@media screen and (max-width: 1800px) {
    .address__title {
        font-size: 30px;
    }

    .contacts {
        gap: 20px;
    }

    .contacts__text {
        font-size: 24px;
    }
}

@media screen and (max-width: 1500px) {
    .address__title {
        width: 40%;
    }

    .contacts {
        width: 60%;
    }

    .contacts__map {
        height: 240px;
    }
}

@media screen and (max-width: 1200px) {
    .address__title {
        font-size: 24px;
    }

    .contacts__text {
        font-size: 18px;
    }
}

@media screen and (max-width: 992px) {
    .address__title {
        font-size: 20px;
    }

    .contacts__text {
        font-size: 16px;
    }

    .contacts__map {
        height: 200px;
    }
}

@media screen and (max-width: 768px) {
    .address {
        display: grid;
        justify-content: start;
        gap: 10px;
    }

    .address__title {
        width: 100%;
    }

    .contacts {
        width: 100%;
        border: none;
        padding: 0;
        gap: 10px;
    }
}