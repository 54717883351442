.home {
    width: 95%;
    margin: auto;
    display: grid;
    gap: 100px;
}

.company {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    color: #565656;
    text-align: start;
}

.company__title {
    margin: 0;
    font-size: 50px;
    font-weight: 500;
}

.company__text {
    margin: 0;
    padding-left: 18px;
    font-size: 40px;
    max-width: 930px;
    border-left: 1px solid #000;
}

@media screen and (max-width: 1200px) {
    .home__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 992px) {
    .home__company {
        width: 94%;
    }

    .home__text {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .home {
        gap: 20px;
    }
}