.loading_view_none {
    display: none;
}

.catalog {
    display: grid;
    grid-template-columns: 420px calc(100% - 420px - 50px);
    gap: 50px;
    max-width: 1920px;
    margin: auto;
}

.catalog__title {
    font-weight: 500;
    font-size: 64px;
    line-height: 42px;
    text-align: start;
    color: #565656;
    grid-column: 1/3;
    margin: 40px 0 40px;
}

.version {
    border: 1px solid #565656;
    max-height: 350px;
}

.version__title {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
}

.version__list {
    list-style-type: none;
    padding: 0;
    display: grid;
    gap: 16px;
    text-align: start;
    width: 96%;
    margin: auto;
    font-size: 22px;
    line-height: 25px;
}

.vertion__label {
    display: grid;
    grid-template-columns: 24px calc(100% - 24px - 10px);
    gap: 10px;
}

.version__item {
    width: 24px;
    height: 24px;
}

.version__text {
    margin: 0;
}

.catalog__market {
    display: grid;
    gap: 16px;
}

.list {
    padding: 0;
    display: flex;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0;
}

.list_type_list {
    grid-template-columns: 1fr;
    gap: 16px;
}

.item {
    width: 320px;
    display: flex;
    color: black;
    text-decoration: none;
    text-align: start;
    border: 1px solid #565656;
    justify-content: space-between;
    flex-direction: column;
    min-height: 485px;
}

.item_type_list {
    min-height: 250px;
    width: 100%;
    flex-direction: row;
    border: 1px solid #565656;
}

.item__image {
    width: 320px;
    height: 190px;
    object-fit: contain;
}

.item__image_type_list {
    height: 250px;
    width: 200px;
    object-fit: cover;
}

.item__description {
    width: 95%;
    margin: auto;
    text-decoration: none;
    color: #000;
}

.item__description_type_list {
    padding-left: 20px;
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.item__name {
    font-size: 24px;
    margin-bottom: 0;
}

.item__text {
    display: none;
    font-size: 20px;
}

.item__text_type_list {
    display: inline;
}

.item__text_type_last {
    display: inline;
    font-size: 16px;
    margin: 0;
}

.oreder {
    width: 10%;
}

.item__order {
    width: 95%;
    margin: auto;
    display: grid;
    gap: 20px;
}

.item__order_type_list {
    width: 20%;
    gap: 0;
    justify-items: center;
}

.item__price {
    font-size: 24px;
    margin: 0;
    text-align: center;
}

.item__button {
    width: 130px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    background-color: rgba(235, 186, 142, 0.6);
    border: none;
    justify-self: center;
    margin-bottom: 16px;
}

.buttonMore {
    width: 300px;
    height: 64px;
    font-size: 24px;
    border-radius: 5px;
    background-color: rgba(235, 186, 142, 0.6);
    border: none;
    grid-column: 2/3;
    margin: auto;
}

.catalog__type {
    margin: 0;
    font-size: 24px;
}

.catalog__buttons {
    display: flex;
    gap: 16px;
    align-items: center;
}

.catalog__buttons_type_list {
    grid-column: 1;
}

.catalog__button {
    width: 48px;
    height: 48px;
    background-color: white;
    border: #565656 1px solid;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1800px) {
    .catalog {
        grid-template-columns: 270px calc(100% - 270px - 30px);
        gap: 30px;
    }

    .catalog__title {
        font-size: 50px;
        margin: 30px 0 30px;
    }

    .catalog__button {
        width: 38px;
        height: 38px;
    }

    .version__title {
        font-size: 18px;
    }

    .version__list {
        width: 95%;
    }

    .vertion__label {
        grid-template-columns: 20px 225px;
    }

    .version__text {
        font-size: 16px;
    }

    .item {
        min-height: 368px;
        width: 220px;
    }

    .item_type_list {
        width: 100%;
        min-height: 200px;
        border: 1px solid;
    }

    .item__image {
        width: 220px;
        height: 160px;
    }

    .item__image_type_list {
        width: 150px;
        height: 200px;
    }

    .item__description_type_list {
        padding-left: 10px;
        width: 60%;
    }

    .item__name {
        font-size: 16px;
    }

    .item__text {
        font-size: 16px;
    }

    .item__price {
        font-size: 20px;
    }

    .item__button {
        width: 100px;
        height: 40px;
        font-size: 16px;
    }
    
    .buttonMore {
        width: 270px;
        height: 50px;
        font-size: 20px;
    }
}

@media screen and (max-width: 1500px) {
    .catalog__title {
        font-size: 32px;
        line-height: 32px;
    }

    .item__text {
        margin: 6px 0 6px;
    }
}

@media screen and (max-width: 1200px) {
    .catalog {
        grid-template-columns: 230px calc(100% - 230px - 24px);
        gap: 24px;
    }

    .catalog__title {
        font-size: 24px;
        line-height: 26px;
        margin: 20px 0 30px;
    }

    .catalog__type {
        font-size: 18px;
    }

    .catalog__button {
        width: 32px;
        height: 32px;
    }

    .catalog__ikon {
        width: 20px;
    }

    .version__title {
        font-size: 16px;
    }

    .version__list {
        line-height: 18px;
    }

    .vertion__label {
        grid-template-columns: 16px 190px;
    }

    .version__item {
        width: 16px;
        height: 16px;
    }

    .version__text {
        font-size: 16px;
    }

    .item {
        width: 200px;
    }

    .item_type_list {
        width: 100%;
        min-height: 193px;
    }

    .item__image {
        width: 200px;
    }

    .item__image_type_list {
        width: 110px;
        height: 150px;
    }

    .item__description_type_list {
        padding-left: 6px;
        width: 75%;
    }

    .item__name_type_list {
        margin: 0;
    }
}

@media screen and (max-width: 992px) {
    .catalog {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .catalog__title {
        grid-column: 1;
    }

    .version {
        min-height: 93px;
        width: 80%;
    }

    .version__title {
        text-align: start;
        width: 95%;
        margin: 15px auto 15px;
    }

    .version__list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 15px;
    }

    .vertion__label {
        grid-template-columns: 16px 90%;
    }

    .version__item {
        margin: 0;
    }

    .item {
        min-height: 300px;
    }

    .item_type_list {
        min-height: 193px;
    }

    .item__image {
        height: 130px;
    }

    .item__image_type_list {
        height: 150px;
    }

    .item__price_type_list {
        font-size: 18px;
    }

    .item__button_type_list {
        width: 80px;
        height: 30px;
        font-size: 16px;
    }

    .buttonMore {
        grid-column: 1;
    }
}

@media screen and (max-width: 768px) {
    .catalog__title {
        font-size: 20px;
        line-height: 20px;
    }
}

@media screen and (max-width: 576px) {
    .item {
        min-height: 230px;
        width: 160px;
    }

    .item__image {
        width: 160px;
        height: 90px;
    }

    .item__text_position {
        position: absolute;
        bottom: 8px;
    }

    .item__text_display {
        display: none;
    }

    .item__price {
        font-size: 16px;
    }

    .item__button {
        width: 80px;
        height: 30px;
        font-size: 16px;
    }

    .buttonMore {
        width: 220px;
        height: 45px;
        font-size: 18px;
    }
}

@media screen and (max-width: 376px) {
    .version {
        width: 300px;
    }

    .vertion__label {
        grid-template-columns: 16px 270px;
    }
}