.location {
    text-align: start;
    width: 100%;
    max-width: 1920px;
    margin: auto;
}

.view_none {
    display: none;
}

.location__link {
    text-decoration: none;
    color: #565656;
    font-size: 20px;
}

@media screen and (max-width: 1200px) {
    .location__link {
        font-size: 14px;
    }
}