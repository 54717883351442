.feedback__buttonForm {
  display: block;
    border: 1px solid #565656;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    width: 75px;
    height: 75px;
    position: fixed;
    bottom: 9%;
    right: 4%;
    color: white;
    background-color: #EFD6BE
}

.feedback__buttonForm::after {
  display: block;
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 10px solid #EFD6BE;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation: ring 1.5s infinite;
    animation: ring 3s infinite;
  }

.feedback__ikonButton {
    width: 40px;
}

@media screen and (max-width: 1400px) {
  .feedback__buttonForm {
    width: 55px;
    height: 55px;
  }

    .feedback__ikonButton {
        width: 40px;
    }
}

@media screen and (max-width: 1024px) {
  .feedback__buttonForm {
    width: 44px;
    height: 44px;
  }

    .feedback__ikonButton {
        width: 32px;
    }
  
    .feedback__buttonForm::after {
      border: 6px solid #EFD6BE;
    }
}

@-webkit-keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    75% {
      width: 150px;
      height: 150px;
      opacity: 0;
    }
    100% {
      width: 30px;
      height: 30px;
      opacity: 0;
    }
  }

@keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    75% {
      width: 150px;
      height: 150px;
      opacity: 0;
    }
    100% {
      width: 30px;
      height: 30px;
      opacity: 0;
    }
}