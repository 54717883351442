.certificates {
    max-width: 1920px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.certificates__title {
    font-size: 50px;
    margin: 0;
    font-weight: 500;
    text-align: start;
    color: #565656;
    width: 50%;
}

.certificates__list {
    width: 50%;
    list-style-type: none;
    display: flex;
    gap: 20px;
    padding-left: 18px;
    border-left: #565656 1px solid;
}

.certificates__item {
    width: 99%;
    max-width: 430px;
    height: 600px;
    object-fit: contain;
}

@media screen and (max-width: 1800px) {
    .certificates__title {
        font-size: 30px;
    }

    .certificates__item {
        height: 400px;
    }
}

@media screen and (max-width: 1500px) {
    .certificates__title {
        width: 40%;
    }

    .certificates__list {
        width: 60%;
    }
}

@media screen and (max-width: 1200px) {
    .certificates__title {
        font-size: 24px;
    }

    .certificates__item {
        height: 280px;
    }
}

@media screen and (max-width: 992px) {
    .certificates__title {
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) {
    .certificates {
        display: grid;
    }

    .certificates__title {
        width: 100%;
    }

    .certificates__list {
        width: 100%;
        padding: 0;
        border: none;
    }
}

@media screen and (max-width: 476px) {
    .certificates__list {
        display: grid;
        gap: 10px;
    }
}