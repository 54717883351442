.page {
    width: 95%;
    margin: auto;
}

.title {
    font-size: 64px;
    line-height: 42px;
    margin: 40px 0 40px;
    font-weight: 500;
    color: #565656;
    text-align: start;
}

@media screen and (max-width: 1800px) {
    .title {
        font-size: 50px;
        margin: 30px 0 30px;
    }
}

@media screen and (max-width: 1500px) {
    .title {
        font-size: 32px;
        line-height: 32px;
    }
}

@media screen and (max-width: 1200px) {
    .title {
        font-size: 24px;
        line-height: 26px;
        margin: 20px 0 30px;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 20px;
        line-height: 20px;
    }
}