.invisible {
    display: none;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.8);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.popup__wrap {
    margin: auto;
    display: grid;
    background-color: white;
    border: 1px solid #565656;
}

.popup__buttonClose {
    padding: 0;
    margin: -70px;
    width: 64px;
    height: 64px;
    justify-self: end;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    transform: rotate(45deg);
}

.popup__ikonClose {
    width: 64px;
}

.popup__overlay {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 768px) {
    .popup__buttonClose {
        width: 40px;
        height: 40px;
        margin: -30px;
    }

    .popup__ikonClose {
        width: 40px;
    }
}