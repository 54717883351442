.app {
  max-width: 1920px;
  text-align: center;
  margin: auto;
  display: grid;
  position: relative;
  gap: 40px;
  font-family: 'IBM Plex Sans', 'Roboto', "Helvetica Neue", Helvetica, sans-serif;
}

@media screen and (max-width: 769px) {
  .app {
    gap: 30px;
  }
}

@media screen and (max-width: 576px) {
  .app {
    gap: 10px;
  }
}
