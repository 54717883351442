.feedback {
    max-width: 1920px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.feedback__textBlock {
    width: 56%;
}

.feedback__title {
    font-weight: 500;
    font-size: 64px;
    line-height: 64px;
    text-align: start;
    margin: 0;
    color: #565656;
}

.feedback__text {
    font-weight: 400;
    font-size: 32px;
    line-height: 34px;
    text-align: start;
    color: #565656;
}

@media screen and (max-width: 1800px) {
    .feedback__textBlock {
        width: 47%;
    }

    .feedback__title {
        font-size: 50px;
        line-height: 50px;
    
    }
}

@media screen and (max-width: 1500px) {
    .feedback__title {
        font-size: 40px;
        line-height: 42px;
    }
    
    .feedback__text {
        font-size: 28px;
        line-height: 30px;
    }
}

@media screen and (max-width: 1200px) {
    .feedback__title {
        font-size: 24px;
        line-height: 26px;
    }

    .feedback__text {
        font-size: 18px;
        line-height: 18px;
    }
}

@media screen and (max-width: 768px) {
    .feedback__title {
        font-size: 20px;
        line-height: 22px;
    }

    .feedback__text {
        font-size: 16px;
        line-height: 16px;
    }

    .feedback {
        display: grid;
        gap: 20px;
    }

    .feedback__textBlock {
        width: 100%;
    }
}