.detail {
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 1920px;
    list-style-type: none;
}

.detail__block {
    text-align: start;
}

.detail__title {
    font-size: 40px;
    font-weight: 600;
    color: #565656;
}

.detail__list {
    list-style-type: none;
    padding: 0;
}

.detail__item {
    display: flex;
    min-height: 30px;
    justify-content: space-between;
    font-size: 18px;
    line-height: 24px;
    border-radius: 2px;
}

.detail__item:nth-child(odd) {
    background-color: rgba(245, 217, 191, 0.2);
}

.detail__text {
    margin: auto 10px;
    text-align: end;
    font-size: 24px;
}

.detail__text_name {
    font-weight: 500;
    text-align: start;
    width: 50%;
    font-size: 24px;
    line-height: 48px;
}

@media screen and (max-width: 1800px) {
    .detail__title {
        font-size: 38px;
        margin: 20px 0;
    }
}

@media screen and (max-width: 1500px) {
    .detail__title {
        font-size: 30px;
    }

    .detail__text {
        font-size: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .detail {
        width: 100%;
    }

    .detail__title {
        font-size: 20px;
    }

    .detail__item {
        font-size: 16px;
    }

    .detail__text {
        margin: auto 15px;
    }
}

@media screen and (max-width: 992px) {
    .detail {
        width: 100%;
    }

    .detail__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .detail {
        width: 95%;
    }

    .detail__list {
        display: grid;
        gap: 10px;  
    }

    .detail__item {
        flex-direction: column;
        align-items: flex-start;
    }

    .detail__text_name {
        line-height: 22px;
        width: 100%;
    }

    .detail__text {
        text-align: start;
    }
}