.section__text {
    font-style: normal;
    font-size: 32px;
    height: 60vh;
    line-height: 60vh;
}

@media screen and (max-width:840px) {
    .section__text {
        font-size: 16px;
    }
}