.item {
    display: flex;
    margin: auto;
    max-width: 1920px;
    justify-content: space-between;
    width: 100%;
}

.item__title {
    font-size: 50px;
    line-height: 50px;
    font-weight: 500;
    text-align: start;
    margin: 0;
    color: #565656;
}

.item__block {
    display: flex;
    gap: 70px;
    max-width: 948px;
}

.item__description {
    width: 50%;
    display: grid;
    gap: 30px;
}

.item__textBlock {
    height: 300px;
    color: #565656;
    text-align: start;
    border-left: #565656 solid 1px;
    padding-left: 18px;
}

.item__text {
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    margin: 0;
}

.item__subtext {
    font-size: 30px;
    margin: 0;
    margin-top: 16px;
}

.item__imgBlock {
    display: flex;
}

.item__wrapper {
    width: auto;
}

.item__image {
    width: 100%;
}

.invisible {
    display: none;
}

@media screen and (max-width: 1800px) {
    .item__title {
        font-size: 30px;
        line-height: 30px;
    }

    .item__block {
        gap: 50px;
        max-width: 818px;
    }

    .item__description {
        gap: 20px;
    }

    .item__textBlock {
        height: 200px;
    }

    .item__text {
        font-size: 30px;
        line-height: 30px;
    }

    .item__subtext {
        font-size: 20px;
        line-height: 20px;
    }
}

@media screen and (max-width: 1500px) {
    .item__description {
        width: 340px;
    }

    .item__block {
        max-width: 618px;
    }
}

@media screen and (max-width: 1200px) {
    .item__block {
        gap: 22px;
        max-width: 534px;
    }

    .item__title {
        font-size: 24px;
        line-height: 26px;
    }

    .item__description {
        width: 256px;
    }

    .item__textBlock {
        height: 200px;
    }

    .item__text {
        font-size: 24px;
        line-height: 22px;
    }

    .item__subtext {
        font-size: 18px;
        line-height: 18px;
    }
}

@media screen and (max-width: 992px) {
    .item__title {
        font-size: 20px;
        line-height: 22px;
    }

    .item__block {
        max-width: 411px;
    }

    .item__description {
        width: 194px;
        gap: 35px;
    }

    .item__textBlock {
        height: 170px;
    }

    .item__text {
        font-size: 20px;
        line-height: 20px;
    }

    .item__subtext {
        font-size: 16px;
        line-height: 18px;
    }
}

@media screen and (max-width: 768px) {
    .item {
        flex-direction: column;
        gap: 20px;
    }

    .item__block {
        max-width: 100%;
    }

    .item__description {
        width: 45%;
    }

    .item__textBlock {
        height: 150px;
    }
}

@media screen and (max-width: 568px) {
    .item__block {
        flex-direction: column;
        gap: 10px;
    }

    .item__description {
        width: 98%;
        gap: 10px;
    }

    .item__textBlock {
        height: 110px;
        padding-left: 10px;
    }
}