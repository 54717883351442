.work {
    width: 100%;
    margin: auto;
    display: grid;
    gap: 70px;
    color: #565656;
}

.work__title {
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    text-align: start;
    margin: 0;
}

.work__stepsList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 10px;
    justify-content: center;
}

.work__step {
    display: flex;
    background-image: url(../../images//иконки/work/arrow_y14ba9jgjmdw.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 50px;
    min-width: 285px;
}

.work__step:last-of-type {
    background-image: none;
    min-width: 225px;
}

.step {
    width: 225px;
    display: grid;
    gap: 20px;
    justify-items: center;
    grid-template-rows: 115px 115px 236px;
    align-items: start;
}

.step__img {
    width: 115px;
    height: 115px;
}

.step__name {
    width: 100%;
    font-size: 27px;
    font-weight: 500;
    color: #1E6A7E;
    margin: 0;
    border-bottom: #1E6A7E solid 6px;
    height: 115px;
}

.step__description {
    margin: 0;
    font-size: 20px;
}

@media screen and (max-width: 1800px) {
    .work {
        gap: 50px;
    }
    .work__title {
        font-size: 40px;
        line-height: 40px;
    }

    .work__step {
        background-size: 44px;
        min-width: 249px;
    }

    .step {
        grid-template-rows: 90px 115px 208px;
        width: 196px;
    }

    .step__name {
        font-size: 24px;
    }

    .step__description {
        font-size: 18px;
    }

    .step__img {
        width: 90px;
        height: 90px;
    }
}

@media screen and (max-width: 1600px) {
    .work__stepsList {
        display: grid;
        grid-template-columns: 315px 315px 315px;
        gap: 30px;
        margin: auto;
    }

    .work__step {
        min-width: 315px;
    }

    .step {
        width: 238px;
        grid-template-rows: 90px 95px 158px;
        gap: 10px;
    }

    .step__name {
        height: 80px;
    }
}

@media screen and (max-width: 1200px) {
    .work {
        gap: 30px;
    }

    .work__title {
        font-size: 32px;
    }

    .work__stepsList {
        grid-template-columns: 290px 290px 290px;
        gap: 10px;
        row-gap: 0;
    }

    .work__step {
        min-width: 290px;
    }

    .step {
        width: 235px;
    }
}

@media screen and (max-width: 992px) {
    .work__stepsList {
        grid-template-columns: 300px 300px;
        gap: 20px;
        row-gap: 0;
    }

    .step {
        grid-template-rows: 60px 65px 119px;
    }

    .step__img {
        width: 60px;
        height: 60px;
    }

    .step__name {
        font-size: 20px;
        height: 60px;
        border-bottom: #1E6A7E solid 4px;
    }

    .step__description {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .work {
        gap: 10px;
    }

    .work__title {
        font-size: 24px;
    }

    .work__stepsList {
        grid-template-columns: 255px 255px;
    }

    .work__step {
        min-width: 255px;
        background-size: 35px;
    }

    .step {
        width: 210px;
    }
}

@media screen and (max-width: 568px) {
    .work__title {
        font-size: 20px;
    }

    .work__stepsList {
        gap: 15px;
        grid-template-columns: 300px;
    }

    .work__step {
        background-image: url(../../images/иконки/work/arrow_down.svg);
        background-position: bottom;
    }

    .step {
        width: 100%;
        grid-template-rows: 60px 65px 92px;
    }
}