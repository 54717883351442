.news {
    width: 100%;
    max-width: 1920px;
    display: flex;
}

.news__line {
    width: 1px;
    height: 100%;
    background-color: #000;
    margin-right: 40px;
    margin-left: 25px;
}

.news:nth-child(even) {
    flex-direction: row-reverse;
}

.news__image {
    object-fit: cover;
    width: 100%;
}

.news__block {
    width: 50%;
    display: grid;
    grid-template-rows: 24px 70px 300px;
    gap: 15px;
    text-align: start;
    justify-content: start;
    text-decoration: none;
    color: #565656;
}

.news__description {
    text-align: start;
    margin: 0;
    padding: 0;
    list-style-type: disc;
    width: 50%;
    display: grid;
    gap: 15px;
}

.news__title {
    font-size: 50px;
    font-weight: 500;
    color: #565656;
    margin: 0;
}

.news__text {
    font-size: 24px;
    margin: 0;
    color: #565656;
}

.news__date {
    font-size: 20px;
}

@media screen and (max-width: 1800px) {
    .news__block {
        grid-template-rows: 24px 40px 300px;
    }

    .news__title {
        font-size: 40px;
    }
}

@media screen and (max-width: 1500px) {
    .news__block {
        grid-template-rows: 16px 40px 180px;
    }

    .news__description {
        gap: 12px;
    }
    
    .news__title {
        font-size: 32px;
    }

    .news__text {
        font-size: 20px;
    }

    .news__date {
        font-size: 16px;
    }
}

@media screen and (max-width: 1200px) {
    .news__title {
        font-size: 24px;
    }
    
    .news__text {
        font-size: 18px;
    }

    .news__line {
        margin-right: 28px;
    }
}

@media screen and (max-width: 992px) {
    .news__text { 
        font-size: 16px;
    }
}

@media screen and (max-width: 850px) {
    .news__title {
        font-size: 20px;
        margin: 10px 0;
    }

    .news {
        flex-direction: column;
        border-bottom: 1px #000 solid;
        padding-bottom: 20px;
        gap: 10px;
    }

    .news:last-of-type {
        border-bottom: none;
    }

    .news__block {
        grid-template-rows: 18px 26px 250px;
        width: 100%;
    }

    .news:nth-child(even) {
        flex-direction: column;
    }

    .news__title {
        margin: 0;
    }

    .news__image {
        max-height: 250px;
    }

    .news__line {
        display: none;
    }

    .news__description {
        width: 97%;
        padding-left: 20px;
    
    }
}

@media screen and (max-width: 670px) {
    .news__block {
        grid-template-rows: 18px 26px 215px;
    }
}

@media screen and (max-width: 570px) {
    .news__block {
        grid-template-rows: 18px 26px 182px;
    }
}

@media screen and (max-width: 450px) {
    .news__block {
        grid-template-rows: 18px 26px 138px;
    }
}

@media screen and (max-width: 370px) {
    .news__block {
        grid-template-rows: 18px 26px 112px;
    }
}