.header {
    width: 95%;
    max-width: 1920px;
    margin: auto;
    padding: 0 20px;
    margin-top: 20px;
    padding-top: 10px;
    position: sticky;
    top: 0px;
    left: 0px;
    background-color: #fff;
    z-index: 10;
}

.header__navigaiton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: flex;
    text-decoration: none;
    align-items: center;
}

.logo__image {
    width: 130px;
    height: 210px;
}

.logo__titleBlock {
    margin-left: 40px;
}

.logo__text {
    font-size: 58px;
    line-height: 58px;
    font-weight: 500;
    text-align: start;
    color: #1D6B7E;
    margin: 0;
}

.logo__text_type_last {
    font-size: 32px;
    line-height: 32px;
}

.header__info {
    display: flex;
    gap: 26px;
}

.contacts {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: auto;
    gap: 15px;
}

.contacts__socials {
    display: flex;
    gap: 15px;
}

.contacts__social {
    width: 50px;
    height: 50px;
}

.contacts__phone {
    margin: 0;
    font-size: 30px;
    font-weight: 500;
    color: #565656;
}

.contacts__span {
    margin: 0;
    font-size: 24px;
    line-height: 16px;
    color: #565656;
}

.burgerMenu_visible {
    display: flex;
    border: 1px solid #565656;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 70px;
    align-items: center;
    height: 100vh;
    width: 100%;
    justify-content: flex-start;
    z-index: 5;
    flex-direction: column;
}

.burgerMenu_invisible {
    display: none;
}

.burgerMenu__button {
    padding: 0;
    margin: 0;
    border: none;
    background-color: #fff;
}

.burgerMenu__button_type_close {
    border-radius: 15px;
    align-self: end;
}

.burgerMenu__ikonClose {
    border-radius: 50%;
    width: 64px;
}

.burgerMenu__ikon {
    width: 30px;
}

.basket {
    font-size: 22px;
    text-decoration: none;
    color: black;
    text-align: end;
    width: 100%;
    grid-column: 3/4;
}

@media screen and (max-width: 1800px) {
    .logo__image {
        width: 100px;
        height: 150px;
    }

    .logo__titleBlock {
        margin-left: 20px;
    }

    .logo__text {
        font-size: 40px;
        line-height: 40px;
    }

    .logo__text_type_last {
        font-size: 24px;
        line-height: 24px;
    }

    .header__info {
        gap: 20px;
    }

    .contacts__socials {
        gap: 10px;
    }
    
    .contacts__social {
        width: 40px;
        height: 40px;
    }

    .contacts__phone {
        font-size: 24px;
    }
    
    .contacts__span {
        font-size: 18px;
    }
}

@media screen and (max-width: 1400px) {
    .logo__image {
        width: 80px;
        height: 125px;
    }

    .logo__titleBlock {
        margin-left: 10px;
    }

    .logo__text {
        font-size: 32px;
        line-height: 32px;
    }

    .logo__text_type_last {
        font-size: 20px;
        line-height: 20px;
    }

    .contacts {
        gap: 10px;
    }

    .contacts__phone {
        font-size: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .header {
        margin-top: 0;
    }

    .logo__image {
        width: 60px;
        height: 100px;
    }
    
    .logo__text {
        font-size: 24px;
        line-height: 24px;
    }

    .logo__text_type_last {
        font-size: 18px;
        line-height: 18px;
    }

    .contacts__social {
        width: 32px;
        height: 32px;
    }

    .contacts__phone {
        font-size: 18px;
    }
}

@media screen and (max-width: 1024px) {
    .logo__titleBlock {
        margin-left: 4px;
    }

    .contacts {
        gap: 4px;
    }

    .contacts__socials {
        gap: 4px;
    }

    .contacts__social {
        width: 28px;
        height: 28px;
    }

    .contacts__span {
        font-size: 16px;
    }
}

@media screen and (max-width: 992px) {
    .header {
        padding: 10px 14px;
    }

    .logo__image {
        width: 40px;
        height: 80px;
    }

    .logo__text {
        font-size: 20px;
        line-height: 20px;
    }

    .logo__text_type_last {
        font-size: 14px;
        line-height: 14px;
    }

    .header__info {
        gap: 10px;
    }

    .contacts__social {
        width: 24px;
        height: 24px;
    }

    .contacts__phone {
        font-size: 16px;
    }

    .contacts__span {
        font-size: 12px;
    }
}

@media screen and (max-width: 768px) {
    .header {
        padding: 10px 4px;
    }

    .logo__titleBlock {
        padding-left: 6px;
    }

    .logo__text {
        font-size: 20px;
    }

    .logo__text_type_last {
        font-size: 12px;
    }
}

@media screen and (max-width: 576px) {
    .contacts__phone {
        min-width: 265px;
        font-size: 12px;
    }

    .contacts__social {
        width: 18px;
        height: 18px;
    }
}