.footer {
    max-width: 1920px;
    width: 95%;
    margin: auto;
    align-items: center;
    border-top: #505050 solid 1px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer__wrapper {
    min-height: 150px;
    display: grid;
    grid-template-columns: 350px 2fr 1fr 0.3fr;
    gap: 16px;
    align-items: center;
    margin: auto;
}

.footer__text {
    font-size: 24px;
    text-align: start;
    max-width: 350px;
}

.footer__contacts {
    display: flex;
    justify-content: space-around;
}

.footer__contacts_text {
    font-size: 30px;
    text-align: end;
}

.social {
    display: flex;
    gap: 20px;
}

.social__link {
    width: 60px;
}

@media screen and (max-width: 1800px) {
    .footer__wrapper {
        grid-template-columns: 262px 2fr 1fr 0.3fr;
        min-height: 100px;
    }

    .footer {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
    .footer__text {
        font-size: 18px;
    }

    .footer__contacts_text {
        font-size: 20px;
    }

    .social__link {
        width: 50px;
    }
}

@media screen and (max-width: 1550px) {
    .footer__wrapper {
        grid-template-columns: 235px 2fr 1fr 0.3fr;
        min-height: 90px;
    }

    .footer__text {
        font-size: 16px;
        margin: 0;
    }

    .social__link {
        width: 40px;
    }
}

@media screen and (max-width: 1300px) {
    .footer {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .footer__wrapper {
        grid-template-columns: 235px 1fr 1fr 0.3fr;
        gap: 6px;
    }

    .footer__contacts {
        flex-direction: column;
        align-items: center;
    }

    .footer__contacts_text {
        font-size: 18px;
        margin: 0;
    }

    .social__link {
        width: 28px;
    }
}

@media screen and (max-width: 992px) {
    .footer__wrapper {
        grid-template-columns: 1fr 70px;
        align-content: center;
        min-height: 210px;
    }

    .footer__contacts {
        flex-direction: column;
        align-items: flex-start;
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .social {
        grid-row: 1/2;
        grid-column: 2/3;
        gap: 10px;
        justify-content: end;
    }

    .footer__text {
        max-width: none;
    }

    .footer__contacts_text {
        text-align: start;
        margin: 10px 0;
    }

    .footer__text_place_before {
        grid-row: 2/3;
        grid-column: 1/2;
    }

    .footer__text_place_last {
        grid-row: 3/4;
        grid-column: 1/2;
    }
}