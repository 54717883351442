.page {
    width: 95%;
    margin: auto;
}

.loading_view_none {
    display: none;
}

.basket {
    display: grid;
    margin: auto;
    grid-template-columns: calc(100% - 20px - 265px) 265px;
    grid-template-rows: 80px;
    gap: 20px;
    align-items: start;
    justify-content: space-between;
}

.basket__title {
    font-weight: 500;
    font-size: 64px;
    line-height: 42px;
    text-align: start;
    color: #565656;
    margin: 20px auto;
}

.basket__info {
    grid-column: 1/2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1050px;
}

.basket__subtitle {
    font-size: 24px;
    line-height: 26px;
    text-align: start;
}

.basket__delButten {
    width: 50px;
    height: 50px;
    border: none;
    padding: 0;
    margin: 0;
    background-color: white;
}

.basket__delButten_position_item {
    position: absolute;
    right: 24px;
}

.basket__list {
    padding: 0;
    grid-column: 1/2;
    display: grid;
    gap: 30px;
}

.basket__map {
    width: 100%;
    grid-column: 1/3;
}

.item {
    max-width: 950px;
    height: 130px;
    display: flex;
    color: black;
    text-align: start;
    border: 1px solid #565656;
    align-items: center;
    position: relative;
}

.item__link_hight {
    height: 130px;
}

.item__name {
    font-size: 16px;
    text-decoration: none;
    color: #565656;
}

.item__image {
    width: 160px;
    height: 130px;
    object-fit: contain;
}

.item__description {
    width: 38%;
    padding-left: 20px;
}

.item__price {
    font-size: 20px;
    line-height: 144px;
    margin: 0;
}

.item__quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 290px;
}

.item__form {
    width: 95px;
    display: flex;
    justify-content: space-between;
}

.item__count {
    margin: 0;
    height: 24px;
    width: 24px;
    padding: 0;
    background-color: white;
    border: none;
    border-radius: 50%;
}

.item__countIkon {
    height: 24px;
    width: 24px;
}

.item__value {
    width: 35px;
    height: 24px;
    text-align: center;
}

.summe {
    width: 260px;
    height: 490px;
    grid-column: 2/3;
    grid-row: 1/3;
    display: grid;
    justify-items: start;
    border: 1px solid #565656;
}

.summe__title {
    margin: 0;
    font-size: 24px;
    padding: 30px 0 0 30px;
}

.summe__price {
    font-size: 40px;
    margin: 0;
    padding-left: 30px;
}

.summe__list {
    list-style-type: none;
    padding: 0;
    margin: auto;
    display: grid;
    gap: 6px;
    width: 216px;
    text-align: start;
}

.characteristic {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
}

.characteristic__text_margin_none {
    margin: 0;
}

.characteristic__text_adress {
    width: 115px;
    text-align: end;
}

.summe__subtext {
    font-size: 12px;
    width: 216px;
    text-align: start;
    margin: auto;
}

.summe__subButton {
    border: none;
    font-weight: 500;
    width: 155px;
    height: 50px;
    font-size: 16px;
    border-radius: 5px;
    background-color: rgba(235, 186, 142, 0.6);
    margin: auto;
}

@media screen and (max-width: 1200px) {
    .basket__title {
        font-size: 32px;
    }
}

@media screen and (max-width: 1024px) {
    .item {
        display: grid;
        grid-template-columns: 144px 440px 200px;
        grid-template-rows: 144px 100px;
        height: 244px;
    }

    .item__description {
        justify-self: start;
        width: 100%;
    }

    .item__quantity {
        grid-column: 2/3;
        justify-content: space-evenly;
    }

    .item__data {
        grid-column: 1/2;
        grid-row: 2/3;
        text-align: center;
    }
}

@media screen and (max-width: 992px) {
    .basket {
        width: 94%;
    }

    .basket__title {
        width: 94%;
    }

    .basket {
        grid-template-columns: 265px calc(100% - 265px - 20px);
        gap: 20px;
    }

    .summe {
        grid-column: 1;
        max-width: 500px;
        margin: 0;
        grid-row: 3;
        height: 400px;
    }

    .basket__subtitle {
        font-size: 20px;
    }

    .basket__info {
        grid-column: 1/3;
    }

    .basket__list {
        grid-column: 1/3;
    }

    .summe__title {
        padding: 20px 0 0 16px;
    }

    .summe__price {
        font-size: 30px;
        margin: 0;
        padding-left: 16px;
    }

    .summe__subtext {
        text-align: center;
    }

    .basket__map {
        grid-column: 2/3;
        grid-row: 3;
        width: 100%;
        height: 400px;
        border: 1px solid;
    }
}

@media screen and (max-width: 840px) {
    .item {
        grid-template-columns: 144px 67%;
        grid-template-rows: 144px 100px;
        position: relative;
    }

    .item__price {
        display: none;
    }

    .item__quantity {
        grid-row: 2;
    }

    .summe {
        max-width: 400px;
    }
}

@media screen and (max-width: 768px) {
    .basket__title {
        font-size: 24px;
    }

    .basket__subtitle {
        font-size: 16px;
    }

    .basket__list {
        gap: 10px;
    }

    .basket {
        gap: 0;
    }
}

@media screen and (max-width: 578px) {
    .basket__title {
        font-size: 20px;
        margin: 10px;
    }

    .basket__subtitle {
        font-size: 14px;
    }
}

@media screen and (max-width: 480px) {
    .item {
        max-width: 310px;
        grid-template-columns: 100px 200px;
    }

    .item__image {
        width: 100px;
    }

    .summe {
        max-width: 300px;
        padding: 5px;
        width: 290px;
        height: 350px;
    }

    .summe__title {
        padding: 10px 0 0 7px;
        font-size: 18px;
    }

    .summe__price {
        padding-left: 7px;
        font-size: 24px;
    }

    .summe__list {
        width: 290px;
    }

    .basket__map {
        grid-column: 1/3;
        grid-row: 4;
        height: 200px;
        margin-top: 10px;
    }
}