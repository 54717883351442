.promo {
    font-style: normal;
    min-height: 680px;
    display: grid;
    align-items: end;
    margin: auto;
    width: 100%;
    max-width: 1920px;
}

.promo_background_img {
    background-image: url('../../images/homePromo.jpg');
    background-size: cover;
}

.promo__textBlock {
    display: grid;
    gap: 10px;
    margin-bottom: 50px;
}

.promo__title {
    font-size: 96px;
    line-height: 96px;
    font-weight: 500;
    margin: 0;
    max-width: 83%;
    text-align: start;
    color: #565656;
}

.promo__subtitle {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    text-align: start;
    color: #565656;
}

.promo__text {
    font-weight: 500;
    font-size: 38px;
    line-height: 40px;
    width: 60%;
}

@media screen and (max-width: 1800px) {
    .promo {
        min-height: 520px;
    }

    .promo__title {
        font-size: 64px;
        line-height: 66px;
        width: 56%;
    }
}

@media screen and (max-width: 1500px) {
    .promo {
        min-height: 380px;
    }

    .promo__textBlock {
        margin-bottom: 30px;
    }

    .promo__title {
        font-size: 48px;
        line-height: 48px;
        width: 56%;
    }

    .promo__subtitle {
        font-size: 24px;
        line-height: 26px;
    }
}

@media screen and (max-width: 1200px) {
    .promo {
        min-height: 300px;
    }

    .promo__title {
        font-size: 32px;
        line-height: 32px;
        width: 42%;
    }

    .promo__subtitle {
        font-size: 20px;
        line-height: 20px;
    }
}

@media screen and (max-width: 992px) {
    .promo {
        min-height: 250px;
    }

    .promo__textBlock {
        margin-bottom: 15px;
    }

    .promo__title {
        width: 55%;
    }
}

@media screen and (max-width: 768px) {
    .promo {
        min-height: 200px;
    }

    .promo__title {
        font-size: 24px;
        line-height: 26px;
    }

    .promo__subtitle {
        font-size: 16px;
        line-height: 18px;
    }
}

@media screen and (max-width: 576px) {
    .promo {
        min-height: 140px;
    }

    .promo__textBlock {
        margin-bottom: 10px;
    }

    .promo__title {
        font-size: 20px;
        line-height: 22px;
        width: 100%;
    }
}