.page {
    width: 95%;
    margin: auto;
    display: grid;
    justify-items: start;
    gap: 40px;
}

.title {
    font-size: 64px;
    font-weight: 500;
    color: #565656;
    margin: 0;
}

.list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.text {
    font-size: 40px;
    text-align: start;
    color: #565656;
}

.date {
    font-size: 20px;
    margin-top: -40px;
}

.img {
    height: 520px;
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1800px) {
    .page {
        gap: 20px;
    }

    .title {
        font-size: 50px;
    }

    .date {
        margin-top: -20px;
    }

    .text {
        font-size: 20px;
    }

    .img {
        height: 350px;
    }
}

@media screen and (max-width: 1500px) {
    .title {
        font-size: 32px;
        line-height: 32px;
    }
}

@media screen and (max-width: 1200px) {
    .page {
        gap: 15px;
    }

    .title {
        font-size: 24px;
        line-height: 26px;
    }

    .date {
        margin-top: 0;
        font-size: 18px;
    }

    .text {
        font-size: 18px;
    }

    .img {
        height: 250px;
    }
}

@media screen and (max-width: 768px) {
    .page {
        gap: 10px;
    }

    .title {
        font-size: 20px;
        line-height: 20px;
    }

    .date {
        margin-top: 0;
        font-size: 16px;
    }

    .text {
        font-size: 16px;
    }

    .img {
        height: 200px;
    }
}

@media screen and (max-width: 576px) {
    .img {
        height: 150px;
    }
}