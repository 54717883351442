@font-face {
    font-family: 'IBM Plex Sans';
    src: url(./IMBPlexSans/IBMPlexSans-Regular.ttf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url(./IMBPlexSans/IBMPlexSans-Medium.ttf);
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url(./IMBPlexSans/IBMPlexSans-SemiBold.ttf);
    font-style: normal;
    font-weight: 600;
}