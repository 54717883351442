.navigate {
    min-width: 740px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigate__button {
    width: 130px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #565656;
    background-color: white;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navigate__link {
    font-size: 32px;
    text-decoration: none;
    color: #565656;
}

.burgerNavigation {
    display: grid;
    gap: 25px;
    text-align: end;
    width: 190px;
    justify-content: center;
}

@media screen and (max-width: 1919px) {
    .navigate {
        min-width: 600px;
    }

    .navigate__link {
        font-size: 24px;
    }
}

@media screen and (max-width: 1400px) {
    .navigate {
        min-width: 550px;
    }
}

@media screen and (max-width: 1200px) {
    .navigate {
        min-width: 520px;
    }

    .navigate__link {
        font-size: 20px;
    }

    .navigate__button {
        width: 100px;
        height: 38px;
    }

    .navigate__basket {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 1100px) {
    .navigate {
        min-width: 460px;
    }
}

@media screen and (max-width: 992px) {
    .navigate {
        min-width: 360px;
    }

    .navigate__link {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .navigate__link {
        text-align: center;
        font-size: 16px;
    }
}