.item {
    height: 144px;
    display: flex;
    text-align: start;
    border: 1px solid;
    align-items: center;
    width: 95%;
    margin: auto;
    border: none;
}

.item__image {
    width: 140px;
    height: 100px;
    object-fit: contain;
    border-radius: 5%;
}

.item__name {
    text-decoration: none;
    color: black;
    padding-left: 6px;
    width: calc(100% - 140px - 6px - 266px);
}

.item__description {
    text-decoration: none;
    color: black;
    padding-left: 8px;
    width: calc(100% - 265px - 140px - 6px);
}

.item__price {
    font-size: 18px;
    line-height: 144px;
    margin: 0;
}

.item__quantity {
    display: flex;
    gap: 6px;
    align-items: center;
    min-width: 266px;
}

.item__count {
    margin: 0;
    height: 28px;
    width: 28px;
    padding: 0;
    background-color: white;
    border: none;
    border-radius: 50%;
}

.item__value {
    width: 30px;
    height: 24px;
    text-align: center;
}

.option {
    margin: 0 auto;
    width: 95%;
    display: grid;
    grid-template-rows: 10% 90%;
}

.option__title {
    font-size: 24px;
    margin: 0;
    text-align: start;
}

.option__list {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    max-height: 400px;
    overflow-y: scroll;
}

.option__list::-webkit-scrollbar {
    background-color: rgba(235, 186, 142, 0.2);
    border-radius: 8px;
    width: 10px;
}

.option__list::-webkit-scrollbar-thumb {
    background-color: rgba(235, 186, 142, 0.6);
    width: 10px;
    border-radius: 8px;
}

.item__button {
    justify-self: center;
    width: 250px;
    height: 50px;
    border-radius: 5px;
    font-size: 18px;
    background-color: rgba(235, 186, 142, 0.6);
    border: none;
    margin-bottom: 20px;
}

.item__countBlock {
    display: flex;
    gap: 4px;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .item {
        margin: 10px auto;
        display: grid;
        grid-template-columns: 140px calc(100% - 140px);
        grid-template-rows: 100px 40px;
    }

    .imageBlock {
        grid-row: 1/3;
    }

    .item__name {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .item {
        grid-template-columns: 100%;
        grid-template-rows: 100px 70px 30px;
        min-height: 200px;
    }

    .imageBlock {
        grid-row: 1;
        margin: auto;
    }

    .item__name {
        font-size: 14px;
    }

    .item__quantity {
        justify-content: center;
    }

    .item__price {
        font-size: 13px;
        line-height: 15px;
    }

    .item__count_img {
        width: 20px;
    }

    .item__button {
        width: 170px;
        height: 30px;
        border-radius: 5px;
        font-size: 16px;
    }

    .option {
        grid-template-rows: 6% 94%;
    }

    .option__title {
        font-size: 16px;
    }
}