.item {
    max-width: 1920px;
    height: 130px;
    display: flex;
    color: #565656;
    text-align: start;
    border: 1px solid #565656;
    align-items: center;
    position: relative;
    gap: 20px;
}

.item__link_hight {
    height: 130px;
}

.item__name {
    font-size: 16px;
    text-decoration: none;
    color: #565656;
}

.item__image {
    width: 130px;
    height: 130px;
    object-fit: contain;
}

.item__description {
    width: 38%;
}

.item__price {
    font-size: 20px;
    line-height: 144px;
    margin: 0;
}

.item__quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 290px;
}

.item__form {
    width: 95px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item__count {
    margin: 0;
    height: 24px;
    width: 24px;
    padding: 0;
    background-color: white;
    border: none;
    border-radius: 50%;
}

.item__countIkon {
    height: 24px;
    width: 24px;
}

.item__value {
    width: 35px;
    height: 24px;
    text-align: center;
}

.basket__delButten {
    width: 40px;
    height: 40px;
    border: none;
    padding: 0;
    margin: 0;
    background-color: white;
}

.basket__delButten_position_item {
    position: absolute;
    right: 24px;
}

@media screen and (max-width: 1400px) {
    .item {
        height: 148px;
        display: grid;
        grid-template-columns: 130px calc(100% - 130px - 64px - 20px);
        row-gap: 5px;
        grid-template-rows: 80px 60px;
    }
    
    .item__link_hight {
        grid-row: 1/3;
    }

    .item__image {
        height: 140px;
    }

    .item__description {
        width: 100%;
        padding-top: 9px;
    }

    .item__price {
        line-height: 58px;
    }

    .basket__delButten {
        width: 34px;
        height: 34px;
    }

    .basket__delButten_position_item {
        bottom: 16px;
        right: 16px;
    }
}

@media screen and (max-width: 600px) {
    .item {
        grid-template-rows: 130px 60px;
        height: 195px;
        gap: 5px;
    }

    .item__link_hight {
        grid-row: 1/2;
    }

    .item__image {
        height: 130px;
    }

    .item__quantity {
        padding-left: 16px;
        width: 214px;
    }

    .item__name {
        font-size: 14px;
    }

    .item__price {
        font-size: 16px;
    }

    .item__form {
        width: 60px;
    }

    .item__count {
        height: 18px;
        width: 18px;
    }

    .item__countIkon {
        height: 18px;
        width: 18px;
    }
}