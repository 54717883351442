.page {
    width: 95%;
    margin: auto;
}

.page__title {
    font-weight: 500;
    font-size: 64px;
    line-height: 42px;
    text-align: start;
    color: #565656;
    margin: 40px 0 40px;
}

.page__list {
    display: grid;
    gap: 100px;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1800px) {
    .page__title {
        font-size: 50px;
        margin: 30px 0 30px;
    }

    .page__list {
        gap: 80px;
    }
}

@media screen and (max-width: 1500px) {
    .page__title {
        font-size: 32px;
        line-height: 32px;
    }
}

@media screen and (max-width: 1200px) {
    .page__title {
        font-size: 24px;
        margin: 20px 0 30px;
        line-height: 26px;
    }

    .page__list {
        gap: 30px;
    }
}

@media screen and (max-width: 768px) {
    .page__title {
        font-size: 20px;
        line-height: 20px;
    }

    .page__list {
        gap: 20px;
    }
}