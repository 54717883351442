@keyframes rotation {
    from {
          transform: rotate(0deg);
      }
      to {
          transform: rotate(-360deg);
      }
}

.cover {
    position: fixed;
    top: 1px;
    left: 1px;
    width: 100vw;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    animation: rotation 10s linear infinite;
}

.logo__text {
    font-size: 32px;
    font-weight: 500;
    text-align: start;
    padding-left: 60px;
    color: #1D6B7E;
}

@media screen and (max-width: 768px) {
    .logo__text {
        display: none;
    }

    .image {
        width: 60px;
    }
}