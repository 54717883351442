.form {
    width: 500px;
    height: 765px;
    border: 1px solid #565656 ;
}

.form__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 26px;
    margin-top: 30px;
    color: #565656;
}

.form__inputList {
    margin-top: 50px;
    margin-bottom: 20px;
    display: grid;
    gap: 16px;
    padding: 0;
    justify-items: center;
    border: none;
}

.form__input {
    padding-left: 20px;
    width: 400px;
    height: 60px;
    border: 1px solid #565656;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: start;
    border-radius: 5px;
}

.form__button {
    background-color: #EFD6BE;
    color: #565656;
    border: 1px solid #565656;
    border-radius: 5px;
    width: 400px;
    height: 80px;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
}

.form__subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
}

.documentsBlock {
    list-style-type: none;
}

.documentsBlock__title {
    font-size: 24px;
    padding-bottom: 4px;
    margin: 0;
    text-align: start;
    color: #565656;
}

.documentsBlock__title_type_span {
    font-size: 16px;
}

.documentsBlock__block {
    width: 400px;
    display: grid;
    gap: 8px;
    overflow-y: scroll;
    max-height: 150px;
    min-height: 100px;
    padding-top: 10px;
}

.label {
    position: relative;
    display: grid;
    gap: 8px;
}

.documentsBlock__block::-webkit-scrollbar {
    background-color: #fff;
    border-radius: 0px 8px 8px 0px;
    width: 10px;
}

.documentsBlock__block::-webkit-scrollbar-thumb {
    background-color: #64605e;
    width: 10px;
    border-radius: 8px;
}

.documentsBlock__input {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 50%;
    opacity: 0;
    font-size: 0;
    cursor: pointer;
}

.documentsBlock__addButton {
    display: inline-flex;
    height: 30px;
    width: 50%;
    border: none;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    background-color: #EFD6BE;
    color: #565656;
}

.documentsBlock__file {
    text-align: start;
    display: flex;
    justify-content: space-between;
    min-height: 20px;
    padding-bottom: 4px;
    border-bottom: 1px solid #565656;
    margin-right: 8px;
}

.file__text {
    margin: 0;
    color: #565656;
    line-height: 16px;
}

.file__del {
    width: 20px;
    height: 20px;
}


@media screen and (max-width: 1200px) {
    .form {
        width: 430px;
        height: 620px;
    }

    .form__title {
        font-size: 20px;
        line-height: 22px;
        margin-top: 40px;
    }

    .form__inputList {
        margin-top: 20px;
        gap: 10px;
    }

    .form__input {
        width: 350px;
        height: 40px;
        font-size: 14px;
        line-height: 16px;
    }

    .form__button {
        width: 350px;
        height: 50px;
        font-size: 16px;
        line-height: 18px;
    }

    .form__subtext {
        margin-top: 10px;
        font-size: 12px;
        line-height: 16px;
    }

    .documentsBlock__block {
        width: 350px;
        gap: 6px;
    }

    .documentsBlock__title {
        font-size: 20px;
    }

    .documentsBlock__title_type_span {
        font-size: 16px;
    }

}

@media screen and (max-width: 992px) {
    .form {
        width: 370px;
        height: 560px;
    }

    .form__title {
        margin-top: 20px;
    }

    .form__inputList {
        margin-top: 16px;
        gap: 6px;
    }

    .form__input {
        width: 300px;
        height: 30px;
    }

    .form__button {
        width: 300px;
        height: 50px;
    }

    .form__subtext {
        margin-top: 15px;
    }

    .documentsBlock__block {
        width: 300px;
    }
}

@media screen and (max-width: 768px) {
    .form {
        width: 100%;
        min-width: 320px;
    }

    .form__title {
        margin-top: 18px;
    }

    .form__input {
        width: 90%;
        font-size: 12px;
    }

    .documentsBlock__title {
        font-size: 14px;
    }

    .form__button {
        font-size: 14px;
        font-weight: 400;
    }

    .form__subtext {
        font-size: 10px;
    }

    .documentsBlock {
        width: 90%;
    }

    .documentsBlock__block {
        width: 90%;
    }

    .documentsBlock__title_type_span {
        font-size: 14px;
    }
}

@media screen and (max-width: 568px) {
    .form {
        height: 535px;
        min-width: 300px;
    }

    .documentsBlock {
        max-width: 300px;
    }

    .form__inputList {
        margin-top: 10px;
    }

    .form__button {
        width: 250px;
    }

    .form__subtext {
        margin-top: 6px;
    }
}

@media screen and (max-width: 400px) {
    .documentsBlock {
        max-width: 250px;
    }
}