.option__label {
    display: flex;
    gap: 10px;
    margin: 20px 6px;
}

.option__item {
    display: flex;
    gap: 20px;
    text-align: start;
    width: calc(100% - 25px - 10px);
    max-width: 1920px;
}

.option__image {
    width: 140px;
    height: 100px;
    object-fit: contain;
    border-radius: 5%;
}

.option__name {
    text-decoration: none;
    color: black;
}

.option__text {
    font-size: 18px;
    width: calc(100% - 140px - 100px - 120px - 60px);
}

.option__shop {
    width: 100px;
    text-align: end;
}

.option__button {
    width: 120px;
    height: 50px;
    border-radius: 5px;
    font-size: 18px;
    background-color: rgba(235, 186, 142, 0.6);
    border: none;
    margin: 0;
    padding: 0;
    align-self: center;
}

.option__checkbox {
    width: 24px;
}

.option__price {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.margin_none {
    margin: 0;
}

@media screen and (max-width: 1024px) {
    .option__item {
        gap: 10px;
    }

    .option__image {
        width: 100px;
    }

    .option__price {
        font-size: 16px;
    }

    .option__text {
        font-size: 14px;
        width: calc(100% - 100px - 80px - 80px - 30px);
    }

    .option__shop {
        width: 80px;
    }

    .option__button {
        width: 80px;
        height: 30px;
        font-size: 14px;
        border-radius: 5px;
    }
}

@media screen and (max-width: 768px) {
    .text_size_small {
        font-size: 12px;
    }

    .option__checkbox {
        width: 20px;
    }

    .option__item {
        display: grid;
        grid-template-rows: 60px 40px;
        grid-template-columns: 100px calc(100% - 100px - 80px - 35px) 80px;
    }

    .option__image {
        grid-row: 1/3;
    }

    .option__text {
        grid-row: 1/3;
        width: 100%;
    }

    .option__shop {
        align-self: end;
    }

    .option__price {
        margin-top: 0;
    }

    .option__button {
        grid-column: 3/4;
    }

}

@media screen and (max-width: 576px) {
    .option__label {
        gap: 4px;
    }

    .option__checkbox {
        display: none;
    }

    .option__item {
        grid-template-columns: 80px calc(100% - 68px - 70px - 23px) 70px;
        gap: 4px;
        width: 100%;
    }

    .option__image {
        width: 80px;
    }

    .option__text {
        font-size: 13px;
    }

    .option__shop {
        width: 70px;
    }

    .option__button {
        width: 70px;
        height: 28px;
        font-size: 13px;
        border-radius: 5px;
    }
}